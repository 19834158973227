<script setup>
import { computed, defineProps } from 'vue'
import { twMerge } from 'tailwind-merge'

const props = defineProps({
  type: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  url: {
    type: String,
    default: '/',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'base',
  },
  variant: {
    type: String,
    default: 'oval',
  },
  target: {
    type: String,
    default: '_self',
  },
  borderStyle: {
    type: String,
    default: 'base',
  },
  color: {
    type: String,
    default: 'main',
  },
  class: {
    type: String,
    default: '',
  },
})

const sizes = {
  base: '!text-md px-8 py-4 leading-none',
  tiny: '!text-sm px-4 py-1.5 leading-none',
  small: '!text-sm px-8 py-2.5 leading-none',
  large: '!text-lg px-8 py-5 leading-none',
}

const variant = {
  oval: 'rounded-full',
  square: 'rounded-none',
  rounded: 'rounded-md',
}

const borderStyle = {
  faint: 'border border-black/10 dark:border-white/10',
  base: 'border border-transparent',
}

const color = {
  main: 'bg-black text-white dark:bg-main dark:hover:bg-white dark:hover:text-black hover:bg-main',
  outlined: 'dark:text-white hover:bg-black/10',
}

const currentSize = computed(() => sizes[props.size])
const currentVariant = computed(() => variant[props.variant])
const currentBorderStyle = computed(() => borderStyle[props.borderStyle])
const currentColor = computed(() => color[props.color])

const baseClass = computed(() =>
  twMerge(
    `inline-flex items-center justify-center whitespace-nowrap text-center font-body transition-colors font-bold`,
    currentColor.value,
    currentSize.value,
    currentVariant.value,
    currentBorderStyle.value,
    props.class,
    props.disabled &&
      'pointer-events-none !bg-neutral-400 dark:!bg-neutral-700 !text-neutral-200 dark:!text-neutral-500'
  )
)
</script>

<template>
  <a v-if="type === 'external'" :class="baseClass" :href="url" :target="target">
    {{ text }}
    <slot />
  </a>
  <Link
    v-else-if="type === 'link'"
    :class="baseClass"
    :href="url"
    :target="target"
  >
    {{ text }}
    <slot />
  </Link>
  <Submit v-else-if="type === 'submit'" :class="baseClass">
    {{ text }}
    <slot />
  </Submit>
  <span v-else-if="type === 'span'" :class="baseClass">
    {{ text }}
    <slot />
  </span>
  <button v-else :type="type" :disabled="disabled" :class="baseClass">
    {{ text }}
    <slot />
  </button>
</template>
