<template>
  <button
    aria-label="Toggle dark mode"
    class="flex rounded-full left-4 lg:left-auto lg:-right-16 top-6 absolute bg-neutral-300 border border-neutral-400 dark:border-neutral-600 text-black p-0.5 w-11 cursor-pointer z-50"
    :class="{ '!bg-neutral-700': isDark }"
    @click="toggleDark()"
  >
    <span
      class="p-0.5 bg-black text-white rounded-full translate-x-0 transition-all"
      :class="{ '!bg-white !text-black !translate-x-full': isDark }"
    >
      <Icon :name="isDark ? 'Moon' : 'Sun'" class="w-4 h-4" />
    </span>
  </button>
</template>

<script setup>
import { onMounted } from 'vue'
import { useDark, useToggle, useStorage } from '@vueuse/core'
const mode = useStorage('vueuse-color-scheme')

onMounted(() => {
  // Set mode to dark if nothing is set by default.
  // IsDark sets this value to auto on first load, so we want to make sure dark is applied by default there.
  if (mode.value === 'auto') mode.value = 'dark'
})

const isDark = useDark({
  onChanged: (value) => {
    document.documentElement.classList.toggle('dark', value)
  },
})
const toggleDark = useToggle(isDark)
</script>
