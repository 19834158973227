<template>
  <div
    :class="
      twMerge(
        type === 'hidden' ? 'hidden' : '',
        label ? 'mt-4' : '',
        { 'opacity-60 pointer-events-none': disabled },
        wrapperClass
      )
    "
  >
    <label
      class="block"
      :class="{ 'opacity-60 pointer-events-none': disabled }"
    >
      <Label :label="label" />
      <div class="flex items-center relative">
        <Icon v-if="icon" :name="icon" class="absolute w-4 h-4 left-3" />
        <input
          v-if="element === 'input'"
          v-bind="$attrs"
          ref="input"
          v-model="model"
          :name="name"
          :type="type"
          :class="
            twMerge(
              'px-3 py-2 bg-white dark:bg-black dark:bg-opacity-30 border border-neutral-200 dark:border-neutral-700 dark:text-white block w-full rounded-md focus:!border-grape',
              label ? 'mt-1' : '',
              icon ? 'pl-9' : '',
              type === 'password' ? 'pr-10' : '',
              $attrs.class
            )
          "
          :placeholder="placeholder"
          v-on="listenersWithoutInput"
        />
        <textarea
          v-else-if="element === 'textarea'"
          v-bind="$attrs"
          ref="input"
          v-model="model"
          :name="name"
          :type="type"
          :class="
            twMerge(
              'px-3 py-2 bg-white dark:bg-black dark:bg-opacity-30 border border-neutral-200 dark:border-neutral-700 dark:text-white block w-full rounded-md focus:!border-grape',
              label ? 'mt-1' : '',
              icon ? 'pl-9' : '',
              type === 'password' ? 'pr-10' : '',
              $attrs.class
            )
          "
          :placeholder="placeholder"
          v-on="listenersWithoutInput"
        />
        <div
          v-if="type === 'password'"
          class="absolute right-1 mt-[4px] pt-0 pr-1 dark:text-white"
          :class="[{ 'mt-[18px]': !label }]"
          @click="toggleInputType"
        >
          <Icon v-if="isTextVisible" name="EyeOff" />
          <Icon v-else name="Eye" />
        </div>
      </div>
    </label>
    <Error v-if="errorMessage" :message="errorMessage" />
  </div>
</template>

<script>
import { Input } from '@/Components/Shared/Form/Core'
import Error from './Error.vue'
import Label from './Label.vue'
import { twMerge } from 'tailwind-merge'

export default {
  components: {
    Error,
    Label,
  },
  mixins: [Input],

  setup() {
    return {
      twMerge,
    }
  },

  data() {
    return {
      isTextVisible: false,
    }
  },

  methods: {
    toggleInputType() {
      this.$refs.input.type =
        this.$refs.input.type === 'password' ? 'text' : 'password'
      this.isTextVisible = !this.isTextVisible
    },
  },
}
</script>
