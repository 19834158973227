<template>
  <div class="mt-4" :class="{ 'opacity-60 pointer-events-none': disabled }">
    <Label :label="label" />

    <div
      :class="{
        'mt-2': label,
        'flex flex-wrap': inline,
        'space-y-1': !inline,
      }"
    >
      <slot />
    </div>
    <Error v-if="errorMessage" :message="errorMessage" />
  </div>
</template>

<script>
import { Group } from '@/Components/Shared/Form/Core'
import Error from './Error.vue'
import Label from './Label.vue'

export default {
  components: { Error, Label },
  mixins: [Group],
}
</script>
