<script setup>
import CtaButton from '@/Components/Shared/Elements/CtaButton.vue'
import MainMenuList from '@/Components/Shared/Navigation/MainMenuList.vue'
import useRoute from '@/Composables/useRoute'
import helper from '@/Utils/helper'
import { nestedMenu } from '@/Utils/navigation.js'
import { Inertia } from '@inertiajs/inertia'
import { usePage } from '@inertiajs/inertia-vue3'
import dom from '@left4code/tw-starter/dist/js/dom'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useReleaseNotesStore } from '@/Store/release-notes'
import useWatchMounted from '@/Composables/useWatchMounted'

const route = useRoute
const releaseNotesStore = useReleaseNotesStore()

const formattedMenu = ref([])

const sideMenu = computed(() => {
  return nestedMenu(usePage().props.value.navigation)
})

Inertia.on('success', () => {
  formattedMenu.value = helper.toRaw(sideMenu.value)
})

onMounted(() => {
  dom('html').addClass('admin-panel')
  dom('body').removeClass('error-page').removeClass('login').addClass('main')
  formattedMenu.value = helper.toRaw(sideMenu.value)
  releaseNotesStore.fetchPosts()
})

onUnmounted(() => {
  dom('html').removeClass('admin-panel')
})

const releaseNotesReadAt = computed(
  () => usePage().props.value.auth.user?.release_notes_read_at
)

const currentRoute = computed(() => usePage().props.value.activeRoute)
useWatchMounted(currentRoute, () => {
  if (currentRoute.value.includes('release-notes')) {
    releaseNotesStore.markAsRead()
  }
})

const hasNewRelease = computed(() => {
  return releaseNotesStore.hasNewRelease(releaseNotesReadAt.value)
})

const menu = computed(() => {
  return [
    {
      name: hasNewRelease.value ? "What's new" : 'Release Notes',
      icon: hasNewRelease.value ? 'Sparkles' : 'Sparkle',
      href: '/release-notes',
      type: 'link',
      highlight: hasNewRelease.value,
      highlightContent: 'An update is available, click to read',
    },
    {
      name: 'Affiliates Program',
      icon: 'Wallet',
      href: 'https://affiliates.upstream.so/',
      target: '_blank',
    },
    {
      name: 'Help Center',
      icon: 'Zap',
      href: 'https://help.upstream.so',
      target: '_blank',
    },
    {
      name: 'Logout',
      icon: 'LogOut',
      href: route('logout'),
      type: 'link',
      method: 'post',
    },
  ]
})
</script>

<template>
  <div class="flex lg:flex-col flex-1 pb-4">
    <MainMenuList :menu="formattedMenu">
      <li
        v-for="(item, index) in menu"
        :key="item.name"
        class="block px-2 py-1"
        :class="{ 'mt-auto': index === 0 }"
      >
        <CtaButton
          class="!bg-transparent w-full !px-4 text-sm !font-normal !justify-start"
          :class="[
            {
              '!text-grape dark:!text-grape': currentRoute === item.href,
            },
            {
              'animate-pulse !bg-grape dark:!bg-grape hover:!bg-black hover:!text-white dark:hover:!bg-white dark:hover:!text-black':
                item.highlight,
            },
            {
              '!text-black dark:!text-white hover:!text-grape dark:hover:!text-grape':
                !item.highlight,
            },
          ]"
          size="small"
          variant="rounded"
          :border-style="item.highlight ? 'faint' : 'base'"
          :type="item.type || 'external'"
          :target="item.target"
          :href="item.href"
          :method="item.method"
          :as="item.method ? 'button' : 'a'"
        >
          <div class="mr-2">
            <Icon :name="item.icon" class="mr-1" />
          </div>
          <div class="flex flex-col items-start">
            <span :class="{ 'font-bold': item.highlight }">{{
              item.name
            }}</span>
            <span v-if="item.highlight" class="text-xs">{{
              item.highlightContent
            }}</span>
          </div>
        </CtaButton>
      </li>
    </MainMenuList>
  </div>
</template>
