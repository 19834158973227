<script>
import { get } from 'lodash-es'
import Element from './Element.vue'

export default {
  mixins: [Element],
  props: {
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    modelValue: {
      required: false,
    },
    element: {
      type: String,
      default: 'input',
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    wrapperClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    model: {
      get: function () {
        if (this.hasFormContext) {
          return get(this.formContext.model, this.name)
        }

        return this.modelValue
      },
      set: function (value) {
        this.emitInputValue(value)
      },
    },
  },

  watch: {
    model(newVal) {
      if (this.type === 'number') {
        if (newVal < Number(this.$attrs.min)) {
          this.model = this.$attrs.min
        } else if (newVal > this.$attrs.max) {
          this.model = Number(this.$attrs.max)
        }
      }
    },
  },

  methods: {
    focusInput() {
      this.$refs.input.focus()
    },
  },
}
</script>
