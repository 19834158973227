<script setup>
import Breadcrumb from '@/Components/Shared/Navigation/Breadcrumb.vue'
import CtaButton from '@/Components/Shared/Elements/CtaButton.vue'
import { usePage } from '@inertiajs/inertia-vue3'
import { computed } from 'vue'
import helper from '@/Utils/helper'

const user = computed(() => usePage().props.value.auth.user)
</script>

<template>
  <div
    class="px-4 border-b border-neutral-300 dark:border-neutral-800 items-center py-4 flex"
  >
    <Breadcrumb />
    <div class="mr-0 flex flex-col lg:flex-row lg:items-center">
      <span
        class="mr-4 order-2 lg:order-none mt-4 lg:mt-0 js-trial-period-duration"
      >
        <template v-if="user.trail_period === 'Expired'"
          >Trial period has expired</template
        >
        <template
          v-else-if="user.trail_period !== null && user.trail_period < 7"
        >
          Trial period ends {{ helper.formatReadableDateDiff(user.trail_ends) }}
        </template>
      </span>
      <div class="inline-flex w-full lg:w-auto items-center order-1">
        <CtaButton
          type="span"
          variant="rounded"
          class="mr-2 pointer-events-none"
          size="small"
          border-style="faint"
          :class="[
            user.streams_running === user.max_streams
              ? 'bg-danger dark:bg-danger'
              : 'bg-neutral-300 text-neutral-500 dark:bg-neutral-800 dark:text-neutral-400 font-normal',
          ]"
        >
          {{ user.streams_running }} / {{ user.max_streams }} Streams
          <span class="hidden lg:inline ml-1">available</span>
        </CtaButton>
        <CtaButton
          variant="rounded"
          size="small"
          color="primary"
          border-style="faint"
          url="/billing"
          type="link"
          class="block"
        >
          Upgrade <span class="hidden lg:inline">plan</span>
        </CtaButton>
      </div>
    </div>
  </div>
</template>
