<template>
  <div class="flex flex-col" :class="$attrs.class">
    <label class="flex items-center">
      <input
        v-bind="{ ...$attrs, class: undefined }"
        v-model="model"
        :name="name"
        :value="value"
        type="checkbox"
        class="bg-beige dark:bg-neutral-900 border border-neutral-300 dark:border-neutral-700 rounded-sm mr-2"
        v-on="listenersWithoutChange"
      />

      <p class="text-sm" v-html="label"></p>
    </label>

    <Error v-if="errorMessage" :message="errorMessage" />
  </div>
</template>

<script>
import { Checkbox } from '@/Components/Shared/Form/Core'
import Error from './Error.vue'

export default {
  components: { Error },
  mixins: [Checkbox],
}
</script>
