<template>
  <button v-bind="$attrs" class="disabled:opacity-50" :type="type">
    <CtaButton
      class="!w-full"
      type="span"
      variant="rounded"
      border-style="faint"
      size="small"
    >
      <slot>
        <div class="flex flex-row items-center justify-center">
          <svg
            v-if="isProcessing"
            class="animate-spin mr-3 h-3 w-3 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>

          <span :class="{ 'opacity-50': isProcessing }">{{ label }}</span>
        </div>
      </slot>
    </CtaButton>
  </button>
</template>

<script>
import { Submit } from '@/Components/Shared/Form/Core'
import CtaButton from '@/Components/Shared/Elements/CtaButton.vue'

export default {
  components: {
    CtaButton,
  },
  mixins: [Submit],
}
</script>
