import { format, parseISO, formatDistance } from 'date-fns'

export default {
  cutText(text, length) {
    if (text?.length > length) {
      const string = text.substring(0, length)
      const splitText = string.split(' ')
      return splitText.join(' ').trim() + '...'
    } else {
      return text
    }
  },
  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ''
    }
  },
  onlyNumber(string) {
    if (string) {
      return string.replace(/\D/g, '')
    } else {
      return ''
    }
  },
  formatCurrency(number) {
    if (number) {
      const formattedNumber = number.toString().replace(/\D/g, '')
      const rest = formattedNumber.length % 3
      let currency = formattedNumber.substr(0, rest)
      const thousand = formattedNumber.substr(rest).match(/\d{3}/g)
      let separator

      if (thousand) {
        separator = rest ? '.' : ''
        currency += separator + thousand.join('.')
      }

      return currency
    } else {
      return ''
    }
  },
  isset(obj) {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === 'object' || Array.isArray(obj)) {
        return Object.keys(obj).length
      } else {
        return obj.toString().length
      }
    }

    return false
  },
  toRaw(obj) {
    return JSON.parse(JSON.stringify(obj))
  },
  toRGB(colors) {
    const tempColors = Object.assign({}, colors)
    const rgbColors = Object.entries(tempColors)
    for (const [key, value] of rgbColors) {
      if (value.replace('#', '').length === 6) {
        const aRgbHex = value.replace('#', '').match(/.{1,2}/g)
        tempColors[key] = (opacity = 1) =>
          `rgb(${parseInt(aRgbHex[0], 16)} ${parseInt(
            aRgbHex[1],
            16
          )} ${parseInt(aRgbHex[2], 16)} / ${opacity})`
      }
    }
    return tempColors
  },
  formatDate(date, pattern = 'MMM, dd yyyy') {
    const parse = parseISO(date)
    return format(parse, pattern)
  },
  formatReadableDateDiff(dueDate) {
    return formatDistance(new Date(dueDate), new Date(), {
      addSuffix: true,
    })
  },
  getClosest(event, selector) {
    const { target } = event
    return target.closest(selector)
  },
}
